import { setSessionItem } from '../services/sessionManagerService';
import { SESSION_CHAT_STATUS } from './constants';

export default function minimizeChat() {
	//document.getElementsByClassName('connect-customer-interface')[0].style.display = 'none';
	document.getElementsByClassName('connect-customer-interface',)[0].style.visibility = 'hidden';
	
	document.getElementById('floatingBtn').style.display = 'flex';
	setSessionItem(SESSION_CHAT_STATUS, 'minimized');
	
	const floatingBtn = document.getElementById('floatingBtn');
	if (floatingBtn) {
		floatingBtn.focus();
	}

	const chatEvent = new CustomEvent('CHAT_ACTIVITY', {
		bubbles: true,
		detail: { event: 'CHAT_MINIMIZE' },
	});
	
	window.dispatchEvent(chatEvent);
}
