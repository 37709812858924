import { getCookieItem, persistIdentifierValue } from '../services/cookieManagerService';
import { getAllSessionItems } from '../services/sessionManagerService';
import {
	HTTP_RESOURCE_CHAT_SESSION,
	HTTP_HEADER_CONTACT_ID,
	CHAT_CONTACT_ID,
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';

// Mandatory attributes
// apiGatewayEndpoint, useBasicAuth from input
// oAuthToken and partnerId from input
// contactIdValue
export default function updateChatSession(customerDetails, metaDataDetails) {
	try {
		const { apiGatewayEndpoint, useBasicAuth } = metaDataDetails;
		const { oAuthToken, partnerId, identifierType, identifier, sessionId } =
			customerDetails;

		const headers = {};
		const chatSession = window.connect.currentChatSession;
		if (chatSession) {
			headers[HTTP_HEADER_CONTACT_ID] = chatSession.contactId;
		} else {
			headers[HTTP_HEADER_CONTACT_ID] =getCookieItem(CHAT_CONTACT_ID);
		}
		const apiGatewayEndpointValue = getApiGateWayEndpoint(useBasicAuth, apiGatewayEndpoint)

		const url = `${apiGatewayEndpointValue}/${HTTP_RESOURCE_CHAT_SESSION}`;

		const data = getAllSessionItems();
		const chatSessionRequest = {
			data,
			partnerId,
			userIdType: identifierType,
			userId: identifier,
		};

		let request = { 
			headers,
			method: 'put',
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
			authToken: oAuthToken,
			correlationId:  sessionId,
			body: JSON.stringify(chatSessionRequest)
		};
		
		const response = fetchWrapper(url,request,{ key:"UpdateChat", loggingText:"Update Chat contact"})
		if(response==null){
			return false;
		}
		else{
			return response.then((json) => {
				if (json.errors) {
					console.error('Update Chat contact attributes Failed', json);
				}else{
					persistIdentifierValue(identifierType, identifier);
					console.log('Update Chat contact attributes Success', json);
				}
				return true;
			});
		}

	} catch (e) {
		console.error('Update Chat Session call error: ', e);
		return false;
	}
}
