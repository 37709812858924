import Cookies from 'js-cookie';
import {
	CHAT_CUSTOMER_IDENTIFIER_TYPE,
	CHAT_CUSTOMER_IDENTIFIER_VALUE,
	COOKIE_PATH,
	COOKIE_DOMAIN,
	COOKIE_EXPIRY_TIME,
	CHAT_CONTACT_ID,
} from '../utils/constants';


export function getCookieValue(key){
    return Cookies.get(key, {
        path: COOKIE_PATH,
        domain: COOKIE_DOMAIN,
    });
}

export function getCookieItem(key){
    return Cookies.getItem(key);
}

export function setCookieValue(key, value){
    Cookies.set(key, value, {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
		expires: COOKIE_EXPIRY_TIME,
		//sameSite: 'none',
		//secure: true,
	});
}


export function persistIdentifierValue (identifierType, identifierValue) {
	if (identifierType && identifierType) {
		Cookies.set(CHAT_CUSTOMER_IDENTIFIER_TYPE, identifierType, {
			path: COOKIE_PATH,
			domain: COOKIE_DOMAIN,
			expires: COOKIE_EXPIRY_TIME,
			//sameSite: 'Strict',
			//secure: true,
		});
		Cookies.set(CHAT_CUSTOMER_IDENTIFIER_VALUE, identifierValue, {
			path: COOKIE_PATH,
			domain: COOKIE_DOMAIN,
			expires: COOKIE_EXPIRY_TIME,
			//sameSite: 'Strict',
			//secure: true,
		});
	}
};

export function clearCookies  () {
	//const Cookies2 = Cookies.noConflict();
	Cookies.remove(CHAT_CONTACT_ID, {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
	});
	Cookies.remove(CHAT_CUSTOMER_IDENTIFIER_TYPE, {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
	});
	Cookies.remove(CHAT_CUSTOMER_IDENTIFIER_VALUE, {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
	});

	Cookies.remove('metaData', {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
	});
	Cookies.remove('customerInfo', {
		path: COOKIE_PATH,
		domain: COOKIE_DOMAIN,
	});
};
