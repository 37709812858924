import { setSessionItem } from '../services/sessionManagerService';
import { SESSION_CHAT_STATUS } from './constants';

export default function maximizeChat() {
	
	const widget = document.getElementsByClassName('connect-customer-interface');
	widget[0].style.visibility = 'visible';
	widget[0].style.display = 'block';
	
	document.getElementById('floatingBtn').style.display = 'none';
	setSessionItem(SESSION_CHAT_STATUS, 'maximized');

	const textInputArea = document.getElementById('widgetTextbox');
	if (textInputArea) {
		textInputArea.focus();
	}

	const chatEvent = new CustomEvent('CHAT_ACTIVITY', {
		bubbles: true,
		detail: { event: 'CHAT_MAXIMIZE' },
	});
	
	const chatNotificationIcon = document.getElementById('chatNotificationIcon');
	if (chatNotificationIcon) {
		chatNotificationIcon.style.visibility = 'none';
		chatNotificationIcon.style.display = 'none';
	}
	window.dispatchEvent(chatEvent);
}
