// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { setSessionItem } from '../../services/sessionManagerService';
import {
	SESSION_CHAT_STATUS,
} from '../../utils/constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from '../../utils/fetchWrapper';

/**
 * Initiate a chat session within Amazon Connect, proxying initial StartChatContact request
 * through your API Gateway.
 *
 * https://docs.aws.amazon.com/connect/latest/APIReference/API_StartChatContact.html
 *
 * @param {Object} input - data to initate chat
 * @param {string} input.instanceId
 * @param {string} input.contactFlowId
 * @param {string} input.apiGatewayEndpoint
 * @param {string} input.name
 * @param {string} input.initialMessage - optional initial message to start chat
 * @param {string} input.region
 * @param {string} input.contactAttributes
 * @param {object} input.headers
 * @returns {Promise} Promise object that resolves to chatDetails objects
 */
export function initiateChat(input) {
	setSessionItem(SESSION_CHAT_STATUS, 'maximized');

	var attr = JSON.parse(input.contactAttributes);
	const {apiGatewayEndpoint, useBasicAuth,  headers } = input;
	const oAuthToken = attr.oAuthToken;
	
	attr.timeOfChat = new Date().toLocaleString();

	if (window.location) {
		attr.chatStartingPoint = window.location.href;
	}
	if (window.navigator) {
		attr.userAgent = navigator.userAgent;
	}
	delete attr.oAuthToken;

	//const fullName = attr.fname + ' ' + attr.lname;
	var AuxData = {};
	if (attr.auxData) {
		AuxData = attr.auxData;
		delete attr.auxData;
	}
	const initiateChatRequest = {
		InstanceId: input.instanceId,
		ContactFlowId: input.contactFlowId,
		ParticipantDetails: {
			DisplayName: input.name,
		},
		AuxData,
		Attributes: attr,

		Username: AuxData.fname || attr.fname,
	};

	if (input.initialMessage) {
		initiateChatRequest.InitialMessage = {
			ContentType: 'text/plain',
			Content: input.initialMessage,
		};
	}

	let request = { 
		headers: headers,
		method: 'post',
		useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
		authToken: oAuthToken,
		correlationId:  attr.sessionId,
		body: JSON.stringify(initiateChatRequest)
	};

	const response =  fetchWrapper(getApiGateWayEndpoint(useBasicAuth,apiGatewayEndpoint), request,{ key:"ChatInitiator", loggingText:"Chat initiator"});
	return response.then((json) => {
		return json.data
	});

}
