// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';
import PT from 'prop-types';
import { ContentType, PARTICIPANT_TYPES } from '../../datamodel/Model';

export class SystemMessage extends React.PureComponent {
	static propTypes = {
		messageDetails: PT.object.isRequired,
	};

	static defaultProps = {};

	getMessageText = () => {
		console.log('SystemMessage getMessageText');
		console.log(this.props);
		let name = '';
		if (this.props.messageDetails.participantRole === PARTICIPANT_TYPES.AGENT) {
			switch (this.props.messageDetails.content.type) {
				case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_JOINED:
					name = this.props.messageDetails.displayName;
					return 'You have been connected to live agent ' + name;
				case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_LEFT:
					name = this.props.messageDetails.displayName;
					return name + ' has left the chat';
				case ContentType.EVENT_CONTENT_TYPE.CHAT_ENDED:
					return 'Chat has ended!';

				default:
					return '';
			}
		}
		if (
			this.props.messageDetails.participantRole === PARTICIPANT_TYPES.CUSTOMER
		) {
			switch (this.props.messageDetails.content.type) {
				case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_JOINED:
					// name = this.props.messageDetails.displayName;
					// if (name && name.trim().toUpperCase() === 'CUSTOMER') {
					// 	//this is Enrollment special case
					// 	return '';
					// } else {
					// 	return name + ' has joined the chat';
					// }
					return '';
				case ContentType.EVENT_CONTENT_TYPE.PARTICIPANT_LEFT:
					name = this.props.messageDetails.displayName;
					return name + ' has left the chat';
				case ContentType.EVENT_CONTENT_TYPE.CHAT_ENDED:
					return 'Chat has ended!';

				default:
					return '';
			}
		}
	};

	render() {
		return <>{this.getMessageText()}</>;
	}
}
