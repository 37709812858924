import { getCookieValue } from '../services/cookieManagerService';
import { storeAllSessionItems } from '../services/sessionManagerService';
import {
	HTTP_RESOURCE_CHAT_SESSION_RETRIEVE,
	HTTP_HEADER_CONTACT_ID,
	HTTP_HEADER_PARTNER_ID,
	CHAT_CUSTOMER_IDENTIFIER_VALUE,
	HTTP_HEADER_IDENTIFIERTYPE,
	HTTP_HEADER_IDENTIFIER,
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';

// Mandatory attributes
// { oAuthToken, partnerId }  from customerDetails
// { apiGatewayEndpoint, useBasicAuth} from metaDataDetails
// contactIdValue
const retrieveChatSession = async (
	customerDetails,
	metaDataDetails,
	contactIdValue,
	isUpdateSession,
) => {
	try {
		const { apiGatewayEndpoint,  useBasicAuth  } = metaDataDetails;
		const { oAuthToken, partnerId } = customerDetails;

		const headers = {};
		headers[HTTP_HEADER_CONTACT_ID] = contactIdValue;
		headers[HTTP_HEADER_PARTNER_ID] = partnerId;

		// ENABLE FOLLOWING ONCE PROXY ALLOWS NEW HEADERS userIdType and userId
		const userIdType = getCookieValue(CHAT_CUSTOMER_IDENTIFIER_VALUE);
		if (userIdType) {
			headers[HTTP_HEADER_IDENTIFIERTYPE] = userIdType;
		}

		const userId  =getCookieValue(CHAT_CUSTOMER_IDENTIFIER_VALUE);
		if (userId) {
			headers[HTTP_HEADER_IDENTIFIER] = userId;
		}
		const apiGatewayEndpointValue = getApiGateWayEndpoint(useBasicAuth, apiGatewayEndpoint)

		const url = `${apiGatewayEndpointValue}/${HTTP_RESOURCE_CHAT_SESSION_RETRIEVE}`;


		let request = { 
			headers,
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
			authToken: oAuthToken,
			correlationId: metaDataDetails.sessionId
		};

		const response = fetchWrapper(url,request,{ key:"RetrieveChat", loggingText:"Retrieve Chat contact"})
		return response.then((json) => {
			if (json.errors) {
				console.error('Retrieve Chat contact Failed', json);
				throw new Error('Retrieve Chat contact Failed', json);
			}
	
			const sessionRestored = storeAllSessionItems(
				json,
				customerDetails,
				metaDataDetails,
				isUpdateSession,
			);
			return sessionRestored;
		});

	} catch (e) {
		console.error('Retrieve Chat Session data error: ', e);
		return false;
	}
};

export default retrieveChatSession;
