// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { Component } from 'react';
import styled from 'styled-components';
import { Loader } from 'connect-core';
import Chat from './Chat';
import ChatSession from './ChatSession';
import { initiateChat } from './ChatInitiator';
import EventBus from './eventbus';
import './ChatInterface';
import { defaultTheme } from 'connect-theme';
import { FlexRowContainer } from 'connect-theme/Helpers';
import { CHAT_FEATURE_TYPES } from './constants';
import ChatError from './ChatError';
import checkChatStatus from '../../utils/checkChatStatus';
import { SESSION_CHAT_DETAILS } from '../../utils/constants';
import clearSessionStorage, { getSessionItem, setSessionItem } from '../../services/sessionManagerService';
const LoadingWrapper = styled(FlexRowContainer)`
	padding: ${({ theme }) => theme.globals.basePadding};
	height: 100%;
`;

const Wrapper = styled.div`
	padding: ${({ theme }) => theme.globals.basePadding};
	height: 100%;
`;

class ChatContainer extends Component {
	constructor(props) {
		super(props);
		console.log('ChatContainer called...', props);
		this.state = {
			chatSession: null,
			composerConfig: {},
			status: 'NotInitiated',
			finished: false,
			error: ""
		};

		this.submitChatInitiationHandler = this.submitChatInitiation.bind(this);
		this.resumeChatSessionHandler = this.submitResumeChat.bind(this);

		EventBus.on('initChat', this.initiateChatSession.bind(this));
		EventBus.on('resumeChat', this.resumeChatSession.bind(this));
	}

	componentWillUnmount() {
		console.log('componentWillUnmount');
		EventBus.off(this.submitChatInitiationHandler);
		EventBus.off(this.resumeChatSessionHandler);
	}

	initiateChatSession(chatDetails, success, failure) {
		console.log('initiateChatSession');

		this.submitChatInitiation(chatDetails, success, failure);
	}

	resumeChatSession(chatDetails, success, failure) {
		console.log('resumeChatSession ', chatDetails);

		this.submitResumeChat(chatDetails, success, failure);
	}

	/**
	 * Initiate a chat in 2 steps.
	 *
	 * Step 1: Create a chat session within Amazon Connect (more details in ChatInitiator.js)
	 * This step provides us with a 'chatDetails' object that contains among others:
	 * - Auth Token
	 * - Websocket endpoint
	 * - ContactId
	 * - ConnectionId
	 *
	 * Step 2: Connect to created chat session.
	 * Open a websocket connection via Chat.JS (more details in ChatSession.js)
	 *
	 * @param {*} input
	 * @param {*} success
	 * @param {*} failure
	 */
	async submitChatInitiation(input, success, failure) {
		const attr = JSON.parse(input.contactAttributes);
		const oAuthToken = attr.oAuthToken;
		console.log('submitChatInitiation 111', input);

		this.setState({ status: 'Initiating', finished: false });

		try {
			const chatDetails = await initiateChat(input);
			
			const chatSession = await this.openChatSession(
				chatDetails,
				input.name,
				input.region,
				input.stage,
			);

			// store chatDetails in sessionStorage
			setSessionItem(SESSION_CHAT_DETAILS, JSON.stringify(chatDetails));

			this.setState({
				status: 'Initiated',
				chatSession: chatSession,
				composerConfig: {
					attachmentsEnabled:
						(input.featurePermissions &&
							input.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]) ||
						(chatDetails.featurePermissions &&
							chatDetails.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]),
				},
				finished: false,
			});
			window.connect.currentChatSession = chatSession;

			input.oAuthToken = oAuthToken;
			input.partnerId = attr.partnerId;
			success && success(chatSession, input);
		} catch (error) {
			this.setState({ status: 'InitiateFailed', error });
			failure && failure(error);
		}
	}

	async submitResumeChat(input, success, failure) {
		this.setState({ status: 'Initiating', finished: false }, (state) => {
			console.log('State has been set to initiating 111', state);
		});
		this.resumeChat(input, success, failure);

		// setTimeout(() => {
		// 	this.resumeChat(input, success, failure);
		// }, 2000);
	}

	async resumeChat(input, success, failure) {
		try {
			// const chatDetails = await resumingChat(input);
			let chatSession = window.connect.currentChatSession;
			const chatDetails = JSON.parse(
				getSessionItem(SESSION_CHAT_DETAILS),
			);

			if (checkChatStatus() && !chatSession) {
				console.log(
					're-establishing chat session using existing participant token.',
				);
				console.log('Making request inside submitResumeChat ', chatDetails);

				chatSession = await this.openChatSession(
					chatDetails,
					input.name,
					input.region,
					input.stage,
				);
				if (!chatSession) {
					this.resetState();
					return;
				}
				window.connect.currentChatSession = chatSession;
			} else {
				console.log('Reusing existing chat session.');
			}

			console.log('About to set state to Initiated 222');

			this.setState(
				{
					status: 'Initiated',
					chatSession: chatSession,
					composerConfig: {
						attachmentsEnabled:
							(input.featurePermissions &&
								input.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]) ||
							(chatDetails.featurePermissions &&
								chatDetails.featurePermissions[CHAT_FEATURE_TYPES.ATTACHMENTS]),
					},
					finished: false,
				},
				(state) => {
					console.log('State has been set to Initiated', state);
				},
			);
			success && success(chatSession);
		} catch (error) {
			console.error('Initialization Failed12:', error);
			this.setState({ status: 'InitiateFailed',  error});
			failure && failure(error);
		}
	}

	openChatSession(chatDetails, name, region, stage) {
		console.log('openChatSession ', chatDetails);
		const chatSession = new ChatSession(chatDetails, name, region, stage);
		return chatSession.openChatSession().then(() => {
			return chatSession;
		});
	}

	resetState = () => {
		console.log('resetState');
		this.setState({
			status: 'NotInitiated',
			chatSession: null,
			finished: true,
		});
		if (
			document.getElementsByClassName('connect-customer-interface').length > 0
		) {
			const widget = document.getElementsByClassName(
				'connect-customer-interface',
			);
			//widget[0].style.display = "none";
			widget[0].style.visibility = 'hidden';
		}
		console.log(
			'Calling clearSessionStorage from ChatContainer.js resetState ',
		);
		clearSessionStorage();
	};

	render() {
		console.log('Widget Status: ', this.state);

		if (
			'NotInitiated' === this.state.status ||
			'Initiating' === this.state.status
		) {
			if (this.state.finished) {
				if (
					document.getElementsByClassName('connect-customer-interface').length >
					0
				) {
					const widget = document.getElementsByClassName(
						'connect-customer-interface',
					);
					//widget[0].style.display = "none";
					widget[0].style.visibility = 'hidden';
				}
				console.log('finished state');
				return null;
			}
			//Fixing Defect COMS-3662: Delay in loading chat widget loader - Chat widget loader is not shown for 2nd chats
			if (
				document.getElementsByClassName('connect-customer-interface').length > 0
			) {
				const widget = document.getElementsByClassName(
					'connect-customer-interface',
				);
				widget[0].style.visibility = 'visible';
				widget[0].style.display = 'block';
			}
			return (
				<LoadingWrapper center={true}>
					<Loader color={defaultTheme.color.primary} size={30} />
				</LoadingWrapper>
			);
		}

		if ('InitiateFailed' === this.state.status) {
			console.log(
				'Calling clearSessionStorage from ChatContainer.js InitiateFailed render() ',
			);
			const chaErrorEvent = new CustomEvent('CHAT_ACTIVITY', {
				bubbles: true,
				detail: { event: 'CHAT_ERROR', error: this.state.error },
			});
			window.dispatchEvent(chaErrorEvent);
			if (window.chat && window.chat.activityCallback) {
				try {
					window.chat.activityCallback({ event: 'chat_error' });
				} catch (er) {
					console.error('Error in invoking activity callback', er);
				}
			}
			//clearSessionStorage();
			return (
				<Wrapper>
					<ChatError
						headerText='Live agent chat'
						detailText="There's a problem on our end."
						secondaryText='Select End chat to close this window and try again later'
						handleModalClose={this.resetState}
					/>
				</Wrapper>
			);
		}
		return (
			<Chat
				chatSession={this.state.chatSession}
				composerConfig={this.state.composerConfig}
				onEnded={this.resetState}
				{...this.props}
			/>
		);
	}
}

export default ChatContainer;
