// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import * as React from "react";
import styled from "styled-components";
import PT from "prop-types";

const FooterWrapper =  styled.div`
  order: 4;
`;

function createMarkup(content) {
  return {__html: content};
}

export default class ChatActionBar extends React.Component {

  static propTypes = {
    contactStatus: PT.string.isRequired,
    onEndChat: PT.func,
    onClose: PT.func,
    footerConfig: PT.object
  };

  static defaultProps = {
    onEndChat: () => {},
    onClose: () => {},
    footerConfig: {}
  };
  
  render() {
    const {
      footerConfig,
    } = this.props;

    if(footerConfig.render){
      const content = footerConfig.render(this.props);
      return footerConfig.isHTML ? <FooterWrapper dangerouslySetInnerHTML={createMarkup(content)} />
      : <FooterWrapper>{content}</FooterWrapper>
    }

    return (
      <FooterWrapper></FooterWrapper>
    );
  }
}
