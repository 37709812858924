import React from 'react';
import styled from 'styled-components';

const ChatEndWrapper = styled.div`
	display: flex;
	order: 3;
	border: 0.5px solid ${(props) => props.theme.palette.lightGray};
	border-left: 0;
	border-right: 0;
	text-align: center;
`;

const ChatCloseButton = styled.button`
	width: 50%;
	cursor: pointer;
	text-align: center;
	position: relative;
	background: transparent;
	color: rgb(0, 0, 255);
	font-size: 14px;
	border-color: rgb(0, 0, 255);
	border-style: solid;
	border-width: 1px;
	border-radius: 5px;
	padding: 10px 40px;
	transition: all 0.2s linear 0s;
	height: 40px;
	margin-top: 14px;
	margin-bottom: 14px;
	margin-left: 25%;
	margin-right: 25%;
	&:focus {
		outline-offset: 0.1rem !important;
		outline: 3px solid #61a0f7;
	}
`;
export default function ChatEnd() {
	function handleChatCloseHeaderButton() {
		window.setModalState();
		const chatEvent = new CustomEvent('CHAT_ACTIVITY', {
			bubbles: true,
			detail: { event: 'CHAT_CLOSE_CLICK' },
		});
		window.dispatchEvent(chatEvent);
	}

	return (
		<ChatEndWrapper>
			<ChatCloseButton
				id='chat_widget_close_button'
				tabIndex={0}
				aria-label='end chat'
				primary={false}
				className='noBtn chatFonts'
				onClick={handleChatCloseHeaderButton}>
				{' '}
				End Chat
			</ChatCloseButton>
		</ChatEndWrapper>
	);
}
