import { getAllSessionItems } from '../services/sessionManagerService';
import {
	HTTP_RESOURCE_CHAT_SESSION,
	HTTP_HEADER_CONTACT_ID
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';

// Mandatory attributes from input
// apiGatewayEndpoint 
// useBasicAuth
// oAuthToken
// partnerId 

// contactIdValue
export default function persistChatSession(input, contactIdValue) {
	try {
		const { apiGatewayEndpoint, useBasicAuth, oAuthToken, partnerId } = input;
		
		const headers = {};
		headers[HTTP_HEADER_CONTACT_ID] = contactIdValue;
		const apiGatewayEndpointValue = getApiGateWayEndpoint(useBasicAuth, apiGatewayEndpoint)
		const url = `${apiGatewayEndpointValue}/${HTTP_RESOURCE_CHAT_SESSION}`;

		const chatSessionRequest = {
			data: getAllSessionItems(),
			partnerId,
		};

		let request = { 
			method: 'post',
			headers,
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
			authToken: oAuthToken,
			correlationId: input.sessionId,
			body: JSON.stringify(chatSessionRequest)
		};

		

		const response = fetchWrapper(url,request,{ key:"PersistChat", loggingText:"Persist Chat contact"})
		
		try {
			
			return response.then((json) => {
				if (json.errors) {
					console.error('Persist Chat contact attributes Failed', json);
				} else {
					console.log('Persist Chat contact attributes Success', json);
				}
				return json;
			});
			
		} catch (error) {
			console.error('Persist Chat Session HTTP call error: ', error);
			return null;
		}
	
	} catch (e) {
		console.error('Persist Chat Session call error: ', e);
	}
}
