import {
	HTTP_HEADER_CONTACT_ID,
	HTTP_HEADER_CORRELATIONID,
	HTTP_RESOURCE_UPDATECONTACT,
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';

// Mandatory attributes
// apiGatewayEndpoint from metaDataDetails
// oAuthToken and partnerId from customerDetails
// contactIdValue
export default function updateContact(customerInfo, metaData, chatContactId) {
	console.log('customerInfo', customerInfo);
	console.log('metaData', metaData);
	try {
		customerInfo.lname = customerInfo.lname ? customerInfo.lname : '';
		let displayName = customerInfo.fname + ' ' + customerInfo.lname;
		if (
			!customerInfo.fname ||
			undefined === customerInfo.fname ||
			'undefined' === customerInfo.fname
		) {
			displayName = 'Customer';
		}

		customerInfo.displayName = displayName;
		const { apiGatewayEndpoint, useBasicAuth} = metaData;
		const oAuthToken = customerInfo.oAuthToken;
		delete customerInfo.oAuthToken;

		const headers = {};
		headers[HTTP_HEADER_CONTACT_ID] = chatContactId;



		if (customerInfo.sessionId) {
			headers.append(HTTP_HEADER_CORRELATIONID, customerInfo.sessionId);
		}
		const updateRequest = {
			contactId: chatContactId,
			attributes: customerInfo,
			channelId: customerInfo.channelId,
			channelType: customerInfo.channelType,
			partnerId: customerInfo.partnerId,
		};

		const apiGatewayEndpointValue = getApiGateWayEndpoint(useBasicAuth, apiGatewayEndpoint)
		const url = `${apiGatewayEndpointValue}/${HTTP_RESOURCE_UPDATECONTACT}`;

		let request = { 
			method: 'post',
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
			authToken: oAuthToken,
			correlationId:  customerInfo.sessionId,
			body: JSON.stringify(updateRequest)
		};
		
		return fetchWrapper(url,request,{ key:"UpdatingContact", loggingText:"Updating contact"})
	
	} catch (error) {
		console.error('Updating contact Attributes ', error);
	}
}
