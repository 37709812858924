import clearSessionStorage, { getSessionItem, removeSessionItem } from '../services/sessionManagerService';
import checkChatStatusAsync from './checkChatStatusAsync';
import {
	SESSION_CHATWIDGET_LOADING,
	SESSION_CHAT_INFO,
	SESSION_CHAT_STATUS,
	SESSION_CUSTOMER_INFO,
	SESSION_METADATA,
	CONNECT_CUSTOMER_INTERFACE_CSS_CLASS,
	SESSION_CHAT_RESIZED_STATUS,
	COBROWSE_VISITORID,
	COBROWSE_ENROLLMENT_VISITORID
} from './constants';
import {shrinkScreen} from "./resizeChat";

export default function resumeChat(customerDetails, metaDataDetails) {
	resume(customerDetails, metaDataDetails);
}
const SESSION_OLB_TS_USERID = 'ts:userid';


function resume(customerDetails, metaDataDetails) {
	try {
		if (!getSessionItem(SESSION_CHATWIDGET_LOADING)) {
			const activeSessionPromise = checkChatStatusAsync(
				customerDetails,
				metaDataDetails,
				true, //update contacts Attributes if call is from resumeChat, should we update contact in successHandler?
			);
			activeSessionPromise
				.then((activeSession) => {
					if (activeSession) {
						const metaDataObj = getSessionItem(SESSION_METADATA);
						const customerInfo = getSessionItem(SESSION_CUSTOMER_INFO);

						if (metaDataObj && customerInfo) {
							const { apiGatewayEndpoint, chatContainerId } =
								JSON.parse(metaDataObj);
							const { displayName } = JSON.parse(customerInfo);

							const url = window.location;
							if (
								url &&
								url.hostname &&
								url.hostname.includes('onlinebanking.usbank.com')
							) {
								const { chatUserId } = JSON.parse(
									getSessionItem(SESSION_CUSTOMER_INFO),
								);
								const tsUserId = getSessionItem(SESSION_OLB_TS_USERID);

								if (tsUserId && chatUserId && chatUserId !== tsUserId) {
									console.log(
										'Calling clearSessionStorage from resumeChat.js  chatUserId !== tsUserId ',
									);
									clearSessionStorage();
									return;
								}
							}
							const { region, contactFlowId, instanceId } = JSON.parse(
								getSessionItem(SESSION_CHAT_INFO),
							);

							try {
								connect.ChatInterface.resume({
									containerId: chatContainerId, // This is the id of the container where you want the widget to reside
								});

								connect.ChatInterface.resumeChat(
									{
										name: displayName,
										region: region,
										apiGatewayEndpoint: apiGatewayEndpoint,
										contactFlowId: contactFlowId,
										instanceId: instanceId,
										status: 'active',
									},
									successHandler,
									failureHandler,
								);
							} catch (e) {
								console.error(
									'Exception at connect.ChatInterface.resumeChat',
									e,
								);
							}
						} else {
							console.log('No chat metadata available to resume');
						}
					} else {
						console.log(
							'Calling clearSessionStorage from resumeChat.js checkChatStatusAsync() returned NOT true',
						);
						clearSessionStorage();
						console.log('No session found to resume');
					}
				})
				.catch((error) => {
					console.error(
						'Exception inside checkChatStatusAsync Calling clearSessionStorage from resumeChat.js',
						error,
					);
					clearSessionStorage();
				});
		}
	} catch (error) {
		console.error('Exception inside resumeChat', error);
	}
}

function successHandler(chatSession) {
	//document.getElementById('section-chat').style.display = 'block';
	removeSessionItem(SESSION_CHATWIDGET_LOADING);
	const status = getSessionItem(SESSION_CHAT_STATUS);
    const resizedStatus =  getSessionItem(SESSION_CHAT_RESIZED_STATUS);
	/*
	 * 1. If GLANCE scripts are enabled and visitorId is not set under window.GLANCE_COBROWSE
	 * then set visitorId value to Chat Contact ID value.
	 */
	if(window && window.GLANCE){
		try{
			let visitorId = undefined;
			if (window.GLANCE_COBROWSE) {
				visitorId = window.GLANCE_COBROWSE.visitorid || window.GLANCE_COBROWSE.visitorId;
			}
			// if no visitorId is defined or if its set to some dummy values then use ContactID as visitorId.
			if(!visitorId || visitorId === COBROWSE_VISITORID || visitorId === COBROWSE_ENROLLMENT_VISITORID){
				window.GLANCE.Presence.Visitor.setVisitorId(chatSession.contactId);
			}
		} catch(ex){
			console.log("Error while setting Cobrowse visitorId");
		}	
	}

	if (status === 'minimized') {
		document.getElementsByClassName(
			CONNECT_CUSTOMER_INTERFACE_CSS_CLASS,
		)[0].style.visibility = 'hidden';
		document.getElementById('floatingBtn').style.display = 'flex';
	}
	if (status === 'maximized') {
		const widget = document.getElementsByClassName(
			CONNECT_CUSTOMER_INTERFACE_CSS_CLASS,
		);
		widget[0].style.visibility = 'visible';
		widget[0].style.display = 'block';
		document.getElementById('floatingBtn').style.display = 'none';
	}
    if (`${resizedStatus}` === `true`) {
		shrinkScreen();
	}
	chatSession.onChatDisconnected(function (data) {
		console.log('##Chat Disconnected!', data);
		//document.getElementById('section-chat').style.display = 'none';
	});
	chatSession.loadPreviousTranscript();
	if (window.chat && window.chat.activityCallback) {
		try {
			window.chat.activityCallback({ event: 'chat_resume' });
		} catch (er) {
			console.error('Error in invoking activity callback', er);
		}
	}

	const chatResumeEvent = new CustomEvent('CHAT_ACTIVITY', {
		bubbles: true,
		detail: { event: 'CHAT_RESUME' },
	});
	window.dispatchEvent(chatResumeEvent);
}

function failureHandler(error) {
	removeSessionItem(SESSION_CHATWIDGET_LOADING);
	console.log(error);
}
