import {
	SESSION_CHAT_DETAILS,
	SESSION_METADATA,
	SESSION_CUSTOMER_INFO,
	SESSION_CHAT_STATUS,
	SESSION_CHAT_INFO,
	SESSION_CHAT_CHANNEL_TYPE,
	SESSION_CHAT_RESIZED_STATUS,
	SESSION_CHAT_ENDED,
	SESSION_CHATWIDGET_LOADING,
	CHAT_CONTACT_ID,
	CHAT_CUSTOMER_IDENTIFIER_TYPE,
	CHAT_CUSTOMER_IDENTIFIER_VALUE
} from '../utils/constants';
import { clearCookies } from './cookieManagerService';


export function getSessionItem(key){
    return sessionStorage.getItem(key);
}
export function setSessionItem(key, value){
    sessionStorage.setItem(key, value);
}

export function removeSessionItem(key){
    sessionStorage.removeItem(key);
}

export function getAllSessionItems() {
	const sessionData = {};
	
    sessionData[SESSION_CHAT_DETAILS] = getSessionItem(SESSION_CHAT_DETAILS);
	sessionData[SESSION_METADATA] = getSessionItem(SESSION_METADATA);
	sessionData[SESSION_CUSTOMER_INFO] = getSessionItem(SESSION_CUSTOMER_INFO);
	sessionData[SESSION_CHAT_STATUS] =getSessionItem(SESSION_CHAT_STATUS);
	sessionData[SESSION_CHAT_INFO] = getSessionItem(SESSION_CHAT_INFO);
	sessionData[SESSION_CHAT_CHANNEL_TYPE] = getSessionItem(SESSION_CHAT_CHANNEL_TYPE);
    sessionData[SESSION_CHAT_RESIZED_STATUS] = getSessionItem(SESSION_CHAT_RESIZED_STATUS);

	sessionData['url'] = window.location.href;
	sessionData['origin'] = new URL(window.location.href).origin;
	
	
    return sessionData;
}


export function saveChatInfo(availabilityResponse){
    if (availabilityResponse) {
        const chatInfo = {
            region: availabilityResponse.region,
            contactFlowId: availabilityResponse.contactFlowId,
            instanceId: availabilityResponse.instanceId,
        };
		setSessionItem(SESSION_CHAT_INFO, JSON.stringify(chatInfo));
    }
}


export function storeAllSessionItems (
	response,
	customerDetails,
	metaDataDetails,
	isUpdateSession,
) {
	if (response && response.data) {
		const sessionData = JSON.parse(response.data);
		if (
			Object.keys(sessionData).length > 0 &&
			sessionData[SESSION_CUSTOMER_INFO] &&
			sessionData[SESSION_METADATA]
		) {
			const data = sessionData;
			const meta_Data = JSON.parse(data[SESSION_METADATA]);
			const custom_info = JSON.parse(data[SESSION_CUSTOMER_INFO]);
			setSessionItem(SESSION_CHAT_DETAILS, data[SESSION_CHAT_DETAILS]);
			setSessionItem(
				SESSION_METADATA,
				JSON.stringify({
					...meta_Data,
					...(isUpdateSession ? metaDataDetails : {}),
				}),
			);
			setSessionItem(
				SESSION_CUSTOMER_INFO,
				JSON.stringify({
					...custom_info,
					...(isUpdateSession ? customerDetails : {}),
				}),
			);
			setSessionItem(SESSION_CHAT_STATUS, data[SESSION_CHAT_STATUS]);
			setSessionItem(SESSION_CHAT_RESIZED_STATUS, data[SESSION_CHAT_RESIZED_STATUS]);
			setSessionItem(SESSION_CHAT_INFO, data[SESSION_CHAT_INFO]);
			setSessionItem(
				SESSION_CHAT_CHANNEL_TYPE,
				data[SESSION_CHAT_CHANNEL_TYPE],
			);
			return true;
		}
	}
	return false;
};

export default function clearSessionStorage() {
	console.log('clearing chat session storage');
	removeSessionItem(SESSION_CHAT_STATUS);
	removeSessionItem(SESSION_CHAT_DETAILS);
	removeSessionItem(SESSION_METADATA);
	removeSessionItem(SESSION_CUSTOMER_INFO);
	removeSessionItem(SESSION_CHAT_ENDED);
	removeSessionItem(SESSION_CHATWIDGET_LOADING);
	//DO NOT Remove this ITEM Ever sessionStorage.removeItem(chat_channelType);
	removeSessionItem(CHAT_CONTACT_ID);
	removeSessionItem(CHAT_CUSTOMER_IDENTIFIER_TYPE);
	removeSessionItem(CHAT_CUSTOMER_IDENTIFIER_VALUE);
	removeSessionItem(SESSION_CHAT_RESIZED_STATUS);
	clearCookies();
}
