import { getSessionItem } from '../services/sessionManagerService';
import {
	CONTACT_STATUS_CONNECTED,
	CONTACT_STATUS_CONNECTING,
	CONTACT_STATUS_DISCONNECTED,
	CONTACT_STATUS_ENDED,
	SESSION_CHAT_DETAILS,
	SESSION_CUSTOMER_INFO,
	SESSION_METADATA,
} from './constants';
//Can Resume Chat? if checkChatStatus is true
export default function checkChatStatus() {
	const chatDetails = getSessionItem(SESSION_CHAT_DETAILS);
	const customerInfo = getSessionItem(SESSION_CUSTOMER_INFO);
	const metaData = getSessionItem(SESSION_METADATA);
	const chatSession = window.connect.currentChatSession;
	if (
		chatSession &&
		(chatSession.contactStatus === CONTACT_STATUS_DISCONNECTED ||
			chatSession.contactStatus === CONTACT_STATUS_ENDED)
	) {
		// Usually when Agent or Customer ends or when connection couldn't be established.
		return false;
	} else if (
		metaData &&
		((customerInfo && chatDetails) ||
			(chatSession &&
				(chatSession.contactStatus === CONTACT_STATUS_CONNECTING ||
					chatSession.contactStatus === CONTACT_STATUS_CONNECTED)))
	) {
		//When there is metadata and customerInfo and chatDetails
		//OR
		//When there is metadata and there is an active session object and valid status.
		return true;
	} else {
		// clearSessionStorage();
		console.log('IN ELSE---', metaData, customerInfo, chatDetails);
		return false;
	}
}
