import { saveChatInfo } from '../services/sessionManagerService';
import {
	HTTP_RESOURCE_INVITE_TO_CHAT,
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';


// Expected Fields for metaData
// apiGateway
// sessionId
// oAuthToken
// useBasicAuth

//Expected Fields for data
// channelId
// subChannel
// channelType
// partnerId
// auxData
export default function inviteToChat(metaData, data) {
	console.log('MetaData', metaData);
	console.log('Data', data);
	try {
		const { apiGatewayEndpoint, oAuthToken , useBasicAuth, sessionId} = metaData;
		const apiGatewayEndpointValue = getApiGateWayEndpoint(useBasicAuth, apiGatewayEndpoint)
		const url = `${apiGatewayEndpointValue}/${HTTP_RESOURCE_INVITE_TO_CHAT}`;

		const decisionRequest = { metaData, data };
	
		let request = { 
			method: 'post',
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint), 
			authToken: oAuthToken,
			correlationId:  sessionId,
			body: JSON.stringify(decisionRequest)
		};
		
		const response =  fetchWrapper(url,request,{ key:"InviteToChat", loggingText:"Invite to chat"})
		response.then((availabilityResponse) => {
			saveChatInfo(availabilityResponse.chatAvailabilityResponse);
		});
		return response;

	} catch (e) {
		console.error('Invite to chat call error: ', e);
	}
}
