const BASE_PATH = process.env.BASE_PATH || ''
const mockServicePath = BASE_PATH ?
  `${BASE_PATH}/mock/` :
  `http://localhost:3000/mock/`

const isOnEnv = (env) => {
  const domain = window.location.origin
  return domain.split('.')[0].indexOf(env) !== -1
}

const formEndPointsByConvention = () => {
  if (isOnEnv('localhost')) {
    return {
      baseUrl :"http://localhost:8080/"
    }
  }
  if (isOnEnv('dev')) {
    return {
      baseUrl :"https://it5-onlinebanking.usbank.com/digital/servicing/chat/"
    }
  }
  if (isOnEnv('it')) {
    return {
      baseUrl :"https://it5-onlinebanking.usbank.com/digital/servicing/chat/"
    }
  }

  if (isOnEnv('uat')) {
    return {
      baseUrl :"https://uat1-onlinebanking.usbank.com/digital/servicing/chat/"
    }
  }

  return {
    baseUrl :"https://onlinebanking.usbank.com/digital/servicing/chat/"
  }
}

export const isLocal = process.env.NODE_ENV === 'development'

const configuration =
  typeof window !== 'undefined' ? formEndPointsByConvention() : {}

export { mockServicePath, configuration }