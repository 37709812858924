import { authApiGateWayEndpoint, unAuthApiGateWayEndpoint } from '../constants/connections';
import { HTTP_HEADER_AUTHORIZATION, HTTP_HEADER_CORRELATIONID, unAuthenticatedChannelsProxy } from './constants';
import request from './fetchRequest';

//Options should consist of:
// method  - which http method to use in the call.
// headers -  list of non standard headers that are needed.
// useBasicAuth -  flag to determine if oauthToken should be used as basic or bearer. (true = basic)
// authToken - token to use in the authorization header.
// correlationId - value to use as the correlation Id
// body - optional body to send

//LoggingOptions should consist of:
// key  - name of the operation for logging purposes
// loggingText - value to append to error message
function getDefaultHeaders (useBasicAuth, authToken,correlationId){

    let tokenType  = 'Bearer'
    if (authToken && authToken !== '') {
        if (useBasicAuth === true) {
            tokenType =  'Basic';
        } 
    } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Auth Token invalid';
    }

    const defaultHeaders = {}
    defaultHeaders['Content-Type']= 'application/json';
    defaultHeaders[HTTP_HEADER_AUTHORIZATION]= `${tokenType} ${authToken}`;
   
    if (correlationId) {
        defaultHeaders[HTTP_HEADER_CORRELATIONID]=  `${correlationId}`;
    }
   

    return defaultHeaders ;
}

function fetchOptions(options) {
    const { method, headers, useBasicAuth, authToken,correlationId, body } = options || {}
   
    let currentHeaders = getDefaultHeaders(useBasicAuth, authToken,correlationId);
    return {
    method: method || 'GET',
    headers: {...currentHeaders, ...headers},
    body
  }
}

export default async function fetchWrapper(path, options, loggingOptions) {
    try {

        const optionsObj = fetchOptions(options)
        console.log(`FetchWrapper: ${loggingOptions.key} : Options: ${JSON.stringify(optionsObj)}`)
		return request(path, optionsObj)
			.then((res) => {
				const json = res.json;
                console.log(`FetchWrapper response: ${JSON.stringify(json)}`);
				return json;
			})
			.catch((error) => {
                const { key, loggingText } = loggingOptions
                let response = { [key] : false };
				if (error && error !== null) {
					response.status = error.status;
					if (error.json && error.json != null) {
						response.description = error.json.code + ':' + error.json.message;
					}
				} else {
					response.description = `${loggingText} HTTP call error: ${error}`;
				}
                console.log(response);
				return response;
			});
    } catch (e) {
        const { loggingText } = loggingOptions
        const errorText =  `${loggingText} call error: `;
        console.error(errorText, e);
        return null;  
    }
}



export function checkUseAuthFlag(useBasicAuth, apiGatewayEndpoint ){
    if(useBasicAuth===undefined || useBasicAuth === null){
        if (apiGatewayEndpoint.includes(unAuthenticatedChannelsProxy)) {
            return true;
        } 
        return false;
    }
    return useBasicAuth;
}

export function getApiGateWayEndpoint( useBaiscAuth, apiGatewayEndpoint){
    if(useBaiscAuth===undefined || useBaiscAuth === null){
        if (apiGatewayEndpoint.includes(unAuthenticatedChannelsProxy)) {
            return unAuthApiGateWayEndpoint;
        } 
        return authApiGateWayEndpoint;
    }
else return useBaiscAuth ? unAuthApiGateWayEndpoint : authApiGateWayEndpoint}