import { saveChatInfo } from '../services/sessionManagerService';
import {
	HTTP_RESOURCE_AVAILABLE
} from './constants';
import fetchWrapper, { checkUseAuthFlag, getApiGateWayEndpoint } from './fetchWrapper';



// Expected Fields for metaData
// apiGateway
// sessionId
// oAuthToken
// useBasicAuth

//Expected Fields for data
// channelId
// subChannel
// channelType
// partnerId
// auxData
export default function chatAvailability(customerInfo, metaData) {
	console.log('customerInfo', customerInfo, 'metaData', metaData);
	try {
		const { apiGatewayEndpoint, queueid, useBasicAuth } = metaData;
		const { oAuthToken, channelId, productCode, partnerId } = customerInfo;
		const getApiGateWayEndpointValue = getApiGateWayEndpoint(useBasicAuth,apiGatewayEndpoint)
		let url = `${getApiGateWayEndpointValue}/${HTTP_RESOURCE_AVAILABLE}?channelId=${channelId}`;

		if (productCode) {
			url = url + `&productCode=${productCode}`;
		} else {
			console.info(
				'ProductCode is not available while checking get availability',
			);
		}
		if (partnerId) {
			url = url + `&partnerId=${partnerId}`;
		} else {
			console.info(
				'partnerId is not available while checking get availability',
			);
		}
		if (queueid) {
			url = url + `&queueid=${queueid}`;
		} else {
			console.info('queueid is not available while checking get availability');
		}

		let request = { 
			useBasicAuth: checkUseAuthFlag(useBasicAuth,apiGatewayEndpoint ), 
			authToken: oAuthToken,
			correlationId: customerInfo.sessionId
		};
		
		const response =  fetchWrapper(url,request,{ key:"ChatAvailability", loggingText:"ChatAvailability"})
		response.then((availabilityResponse) => {
			saveChatInfo(availabilityResponse);
		});
		return response;

	} catch (e) {
		console.error('ChatAvailability error: ', e);
	}
}
