import 'amazon-connect-chatjs';
import {
	ContentType,
} from './datamodel/Model';
import clearSessionStorage from '../../services/sessionManagerService';


// Low-level abstraction on top of Chat.JS
class ChatJSClient {
	session = null;

	constructor(chatDetails, region, stage) {
		console.log('Inside constructor ChatJSClient ', chatDetails, region, stage);
		// Creating a chatSession object with Chat.JS
		// Other operations (connecting, sending message, ...) are then done by interacting
		// with the chatSession object (this.session)
		connect.ChatSession.setGlobalConfig({
			loggerConfig: {
				// optional, the logging configuration. If omitted, no logging occurs
				logger: {
					// optional, a logger object implementation
					debug: (msg) => console.debug(msg), // REQUIRED, can be any function
					info: (msg) => console.info(msg), // REQUIRED, can be any function
					warn: (msg) => console.warn(msg), // REQUIRED, can be any function
					error: (msg) => console.error(msg), // REQUIRED, can be any function
				},
				level: connect.ChatSession.LogLevel.DEBUG, // optional, defaults to: `connect.ChatSession.LogLevel.INFO`
			},
			region: region, // optional, defaults to: "us-west-2"
		});

		this.session = connect.ChatSession.create({
			chatDetails: chatDetails.startChatResult,
			type: 'CUSTOMER',
			options: { region: region },
		});
	}

	clearCache() {
		console.log(
			'Calling clearSessionStorage from ChatSession.js  clearCache() ',
		);
		clearSessionStorage();
	}

	connect() {
		console.log('connect');
		// Intiate the websocket connection. After the connection is established, the customer's chat request
		// will be routed to an agent who can then accept the request.
		return this.session.connect();
	}

	disconnect() {
		console.log('disconnect');
		this.clearCache();
		return this.session.disconnectParticipant();
	}

	onTyping(handler) {
		console.log('onTyping ', handler);
		return this.session.onTyping(handler);
	}

	onEnded(handler) {
		console.log('onEnded ', handler);
		return this.session.onEnded(handler);
	}

	onMessage(handler) {
		console.log('onMessage ', handler);

		return this.session.onMessage(handler);
	}

	onConnectionEstablished(handler) {
		console.log('onConnectionEstablished ', handler);

		return this.session.onConnectionEstablished(handler);
	}

	onConnectionBroken(handler) {
		console.log('onConnectionBroken ', handler);

		this.clearCache();
		return this.session.onConnectionBroken(handler);
	}

	getContactId() {
		console.log('getContactId ');

		return this.session.controller.contactId;
	}

	getParticipantId() {
		console.log('getParticipantId ');

		return this.session.getChatDetails().participantId;
	}

	getTranscript(args) {
		console.log('getTranscript ', args);

		return this.session.getTranscript(args);
	}

	sendTypingEvent() {
		console.log('sendTypingEvent ');

		return this.session.sendEvent({
			contentType: ContentType.EVENT_CONTENT_TYPE.TYPING,
		});
	}

	sendMessage(content) {
		console.log('sendMessage ', content);

		// Right now we are assuming only text messages,
		// later we will have to add functionality for other types.
		return this.session.sendMessage({
			message: content.data,
			contentType: ContentType.MESSAGE_CONTENT_TYPE.TEXT_PLAIN,
		});
	}

	sendAttachment(attachment) {
		console.log('sendAttachment ', attachment);

		return this.session.sendAttachment({ attachment });
	}

	downloadAttachment(attachmentId) {
		console.log('downloadAttachment ', attachmentId);

		return this.session.downloadAttachment({ attachmentId });
	}
}


export default ChatJSClient;