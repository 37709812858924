import { setSessionItem } from '../services/sessionManagerService';
import { SESSION_CHAT_RESIZED_STATUS } from './constants';

 function getElementsID() {
    const chatWidget = document.getElementsByClassName('connect-customer-interface');
    const smallSizeIcon = document.getElementById('chat_widget_small_view');
    const largeSizeIcon = document.getElementById('chat_widget_maximize_view');
    const headerContainer = document.getElementsByClassName('headerWrapper');
    const headerText = document.getElementsByClassName("chatFonts");
    const chatWidth = "320px";
    const isBigScreen = window.innerWidth >= 600
    return {chatWidget, smallSizeIcon, largeSizeIcon, headerContainer, headerText, chatWidth, isBigScreen};
 }
 const heightSize = "100%";
 const block = "block";
 const none = "none";

export function expandScreen() {
    const {chatWidget, smallSizeIcon, largeSizeIcon, headerContainer, headerText, chatWidth, isBigScreen}=getElementsID();
    const chatLargeScreenHeight = "543px";
    chatWidget[0].style.height = isBigScreen ? chatLargeScreenHeight: heightSize;
    chatWidget[0].style.width = isBigScreen ? chatWidth : heightSize;
    largeSizeIcon.style.display = none;
    smallSizeIcon.style.display = block;
    headerContainer[0].style.gridTemplateColumns = "[first] 10% [line2] 70% [line3] 10% [line4] 10% [end]";
    headerText[0].style.fontSize = "18px";
    headerText[0].style.justifySelf = "center";
    headerText[0].style.marginLeft = "0px"
}

export function shrinkScreen(){
    const {chatWidget, smallSizeIcon, largeSizeIcon, headerContainer, headerText, chatWidth, isBigScreen}=getElementsID();
    chatWidget[0].style.height = '219px';
    chatWidget[0].style.width =  isBigScreen ? chatWidth : '235px';
    largeSizeIcon.style.display = block;
    smallSizeIcon.style.display = none;
    headerContainer[0].style.gridTemplateColumns = "[first] 15% [line2] 55% [line3] 15% [line4] 15% [end]";
    headerText[0].style.fontSize = "12px";
    headerText[0].style.justifySelf = "start";
    headerText[0].style.textAlign= "left";
    headerText[0].style.padding="17px 0px";
    headerText[0].style.marginLeft = "10px";
}

export default function resizeChat() {
	const chatWidget = document.getElementsByClassName('connect-customer-interface');
    let resizedStatus = false;
    let eventName = "";
    if (chatWidget[0].clientHeight >= 220) {
        shrinkScreen();
        resizedStatus = true;
        eventName = "CHAT_SHRINK_SIZE";
    } else {
        expandScreen();
        eventName = "CHAT_EXPAND_SIZE";
    }
    const chatScroll = document.getElementById("chat-widget-scroll-container");
    chatScroll.scrollTop = chatScroll.scrollHeight;
    const floatingBtn = document.getElementById('floatingBtn');
    
    setSessionItem(SESSION_CHAT_RESIZED_STATUS, resizedStatus);
	if (floatingBtn) {
		floatingBtn.focus();
	}
	const chatEvent = new CustomEvent('CHAT_ACTIVITY', {
		bubbles: true,
		detail: { event: eventName }
	});
	window.dispatchEvent(chatEvent);
}

export function resetStyle () {
    const chatWidget = document.getElementsByClassName('connect-customer-interface');
    chatWidget[0].style = "";
}