import {
  SESSION_CHATWIDGET_LOADING,
  CHAT_CONTACT_ID,
  COOKIE_DOMAIN,
  COOKIE_PATH,
  COOKIE_EXPIRY_TIME,
  SESSION_CHAT_INFO,
  SESSION_CUSTOMER_INFO,
  SESSION_CHAT_CHANNEL_TYPE,
  SESSION_METADATA,
  SESSION_CHAT_STATUS,
  COBROWSE_CONTACT_ID,
  COBROWSE_VISITORID,
  COBROWSE_ENROLLMENT_VISITORID,
} from "./constants";

import persistChatSession from "./persistChatSession";
import checkChatStatusAsync from "./checkChatStatusAsync";
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from "../services/sessionManagerService";
import { setCookieValue } from "../services/cookieManagerService";
import { checkUseAuthFlag, getApiGateWayEndpoint } from "./fetchWrapper";

const SESSION_OLB_TS_USERID = "ts:userid";

// Expected Fields for metaData
// apiGateway
// sessionId
// oAuthToken
// useBasicAuth

//Expected Fields for data
// channelId
// subChannel
// channelType
// partnerId
// auxData

export default function activateChat(customerInfo, metaData, sessionCallBack) {
  const chatWidgetLoading = getSessionItem(SESSION_CHATWIDGET_LOADING);

  setTimeout(() => {
    console.log("Removing chatWidgetLoading from session from setTimeout");
    removeSessionItem(SESSION_CHATWIDGET_LOADING);
  }, 3000);

  if (!chatWidgetLoading) {
    setSessionItem(SESSION_CHATWIDGET_LOADING, true);
    console.log("Adding chatWidgetLoading to session");
    console.log("customerInfo", customerInfo);
    console.log("metaData", metaData);
    console.log("sessionCallBack", sessionCallBack);

    try {
      const { apiGatewayEndpoint, chatContainerId, useBasicAuth } = metaData;

      //chatAvailability(customerInfo, metaData).then((res) => {
      /* INTFC-20992 activate minimize feature on ChatError screen */

      const activeSessionPromise = checkChatStatusAsync(
        customerInfo,
        metaData,
        false
      );
      activeSessionPromise.then((activeSession) => {
        if (activeSession) {
          // resume chat
          window.connect.resumeChat(customerInfo, metaData);
        } else {
          const chatInfoJsonString = getSessionItem(SESSION_CHAT_INFO);
          const replyData =
            (window["QuantumMetricAPI"] &&
              window["QuantumMetricAPI"]["getReplay"]()) ||
            "";

          customerInfo.auxData = {
            ...metaData.auxData,
            ...customerInfo.auxData,
            quantumSessionReplay: replyData,
          };

          if (chatInfoJsonString) {
            const { region, contactFlowId, instanceId } =
              JSON.parse(chatInfoJsonString);

            customerInfo.queueId = metaData.queueId;
            customerInfo.lname = customerInfo.lname || customerInfo.auxData.lname || '';
            customerInfo.fname = customerInfo.fname || customerInfo.auxData.fname || 'Customer';

            let displayName = customerInfo.fname + " " + customerInfo.lname;
            if (
              !customerInfo.fname ||
              undefined === customerInfo.fname ||
              "undefined" === customerInfo.fname
            ) {
              displayName = "Customer";
            }

            /*
             * 1. If GLANCE scripts are enabled set cobrowseEnabled = true
             * 2. When GLANCE is enabled capture the visitorId from window.GLANCE_COBROWSE
             */
            if (window && window.GLANCE) {
              customerInfo.cobrowseEnabled = true;
              let visitorId = undefined;
              if (window.GLANCE_COBROWSE) {
                visitorId =
                  window.GLANCE_COBROWSE.visitorid ||
                  window.GLANCE_COBROWSE.visitorId;
              }

              if (
                visitorId &&
                visitorId !== COBROWSE_VISITORID &&
                visitorId !== COBROWSE_ENROLLMENT_VISITORID
              ) {
                // if visitorId is available from cookie or session storage use it.
                customerInfo.visitorId = visitorId;
              } else if (
                !visitorId ||
                visitorId === COBROWSE_VISITORID ||
                visitorId === COBROWSE_ENROLLMENT_VISITORID
              ) {
                // if no visitorId is defined or if its set to some dummy values then use ContactID as visitorId.
                customerInfo.visitorId = COBROWSE_CONTACT_ID;
              }
            } else {
              customerInfo.cobrowseEnabled = false;
            }

            customerInfo.displayName = displayName;
            let url = window.location;
            if (
              url &&
              url.hostname &&
              url.hostname.includes("onlinebanking.usbank.com")
            ) {
              customerInfo.chatUserId = getSessionItem(SESSION_OLB_TS_USERID);
            }

            // store chatDetails in sessionStorage
            setSessionItem(SESSION_CUSTOMER_INFO, JSON.stringify(customerInfo));
            setSessionItem(SESSION_CHAT_CHANNEL_TYPE, customerInfo.channelType);

            setSessionItem(
              SESSION_METADATA,
              JSON.stringify({ ...metaData, quantumSessionReplay: replyData })
            );
            delete customerInfo.chatUserId; //delete property so that we do not save sensitive data

            // INITIATE CHAT
            // prepare chat widget
            connect.ChatInterface.init({
              containerId: chatContainerId, // This is the id of the container where you want the widget to reside
            });

            connect.ChatInterface.initiateChat(
              {
                name: displayName,
                region: region,
                apiGatewayEndpoint: getApiGateWayEndpoint(
                  useBasicAuth,
                  apiGatewayEndpoint
                ),
                contactAttributes: JSON.stringify(customerInfo),
                contactFlowId: contactFlowId,
                instanceId: instanceId,
                status: "active",
                sendMessageCallback: sessionCallBack,
                useBasicAuth: checkUseAuthFlag(
                  useBasicAuth,
                  apiGatewayEndpoint
                ),
              },
              successHandler,
              failureHandler
            );
          }
        }
      });
    } catch (error) {
      console.error(
        "Removing chatWidgetLoading from session from activateChat error",
        error
      );
      removeSessionItem(SESSION_CHATWIDGET_LOADING);
    }
  } else {
    console.log(
      "Chat widget is already loading hence will not load again. chatWidgetLoading",
      SESSION_CHATWIDGET_LOADING
    );
  }
}

function successHandler(chatSession, input) {
  console.log("Removing chatWidgetLoading from session from successHandler");
  console.log(input);
  removeSessionItem(SESSION_CHATWIDGET_LOADING);

  console.log("Successfully established session");

  /*
   * 1. If GLANCE scripts are enabled and visitorId is not set under window.GLANCE_COBROWSE
   * then set visitorId value to Chat Contact ID value.
   */
  if (window && window.GLANCE) {
    try {
      let visitorId = undefined;
      if (window.GLANCE_COBROWSE) {
        visitorId =
          window.GLANCE_COBROWSE.visitorid || window.GLANCE_COBROWSE.visitorId;
      }
      // if no visitorId is defined or if its set to some dummy values then use ContactID as visitorId.
      if (
        !visitorId ||
        visitorId === COBROWSE_VISITORID ||
        visitorId === COBROWSE_ENROLLMENT_VISITORID
      ) {
        window.GLANCE.Presence.Visitor.setVisitorId(chatSession.contactId);
      }
    } catch (ex) {
      console.log("Error while setting Cobrowse visitorId");
    }
  }

  // display widget div if hidden
  const widgetDiv = document.getElementsByClassName(
    "connect-customer-interface"
  );

  if (widgetDiv.length > 0) {
    widgetDiv[0].style.display = "block";
  }

  const status = getSessionItem(SESSION_CHAT_STATUS);

  if (status === "minimized") {
    document.getElementsByClassName(
      "connect-customer-interface"
    )[0].style.visibility = "hidden";
    //style.display = "none";
    document.getElementById("floatingBtn").style.display = "flex";
  }
  if (status === "maximized") {
    const widget = document.getElementsByClassName(
      "connect-customer-interface"
    );
    widget[0].style.visibility = "visible";
    widget[0].style.display = "block";
    document.getElementById("floatingBtn").style.display = "none";
  }
  if (window.chat && window.chat.activityCallback) {
    try {
      window.chat.activityCallback({ event: "chat_start" });
    } catch (er) {
      console.error("Error in invoking activity callback", er);
    }
  }
  const chatStartEvent = new CustomEvent("CHAT_ACTIVITY", {
    bubbles: true,
    detail: { event: "CHAT_START" },
  });
  window.dispatchEvent(chatStartEvent);
  console.log("ChatSession inside success Handler", chatSession);
  console.log(CHAT_CONTACT_ID, COOKIE_PATH, COOKIE_DOMAIN, COOKIE_EXPIRY_TIME);

  setCookieValue(CHAT_CONTACT_ID, chatSession.contactId);
  persistChatSession(input, chatSession.contactId);
}

function failureHandler(error) {
  console.log("Removing chatWidgetLoading from session from failureHandler");
  removeSessionItem(SESSION_CHATWIDGET_LOADING);

  console.log(error);
}
