
const visualViewportResizeHandler = (e) => {
	console.log(
		'inside visualViewportResizeHandler, Event is' + JSON.stringify(e),
	);
	const chatWidget = document.getElementsByClassName(
		'connect-customer-interface',
	)[0];
	if (chatWidget) {
		console.log(
			'Before visualViewportResizeHandler connect-customer-interface=' +
				chatWidget.style.maxHeight,
		);

		chatWidget.style.maxHeight = window.visualViewport.height + 'px';
		console.log(
			'After visualViewportResizeHandler  connect-customer-interface=' +
				chatWidget.style.maxHeight,
		);

		var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

		const scrollContainer = document.getElementById(
			'chat-widget-scroll-container',
		);
		if (scrollContainer && iOS) {
			console.log(
				'Before visualViewportResizeHandler setting maxHeight For chat-widget-scroll-container=' +
					scrollContainer.style.maxHeight,
			);
			const maxHeightForScroller = window.visualViewport.height - 106 + 'px';
			scrollContainer.style.maxHeight = maxHeightForScroller;

			console.log(
				'After visualViewportResizeHandler setting maxHeight For chat-widget-scroll-container=' +
					scrollContainer.style.maxHeight,
			);
		}
		const textInputArea = document.getElementById('widgetTextbox');
		if(textInputArea) {
			textInputArea.scrollTop = textInputArea.scrollHeight;
		}
	}
};

export const visualViewportHandler = (e) => {
	visualViewportResizeHandler(e);
	const scrollContainer = document.getElementById(
		'chat-widget-scroll-container',
	);
	if (scrollContainer) {
		scrollContainer.scrollTop = scrollContainer.scrollHeight;
	}
};

export const visualViewportScrollHandler = (e) => {
	console.log('Inside visualViewportScrollHandler Handling Scroll');
	visualViewportResizeHandler(e);
	//DO NOT ENABLE THIS LINE FOR SCROLL HANDLER ELSE DEFAULT SCROLLING WITH STOP
	//scrollContainer.scrollTop = scrollContainer.scrollHeight;
};
