import { SESSION_CUSTOMER_INFO } from '../utils/constants';
import clearSessionStorage, { getSessionItem } from '../services/sessionManagerService';

export const tealiumHandler = (e) => {
    const customInfoStore = getSessionItem((SESSION_CUSTOMER_INFO));
    const customerInfo = JSON.parse(customInfoStore || "{}");
    const partnerId = (customerInfo.partnerId || "").replace(/([^\w]+|\s+)/g, '').toLowerCase();
    if (window.publisherFW && partnerId  === "usbank") {
    const event = e.detail && e.detail.event;
    let transactionStatus = "";
    let datasource = "pageView";
    let currentPage = "";
    let errorMessage = "";
    let erroType = false;
    if (event) {
        switch (event) {
            case 'CHAT_START':
                console.log('CHAT_START event', e);
                transactionStatus = "start chat";
                currentPage = "omni:banker chat:session start";
                break;
            case 'CHAT_RESUME':
                console.log('CHAT_RESUME event', e);
                transactionStatus = "resume chat";
                currentPage = "omni:banker chat:session resume";
                break;
            case 'CHAT_MAXIMIZE':
                console.log('CHAT_MAXIMIZE event', e);
                //transactionStatus = "maximize chat";
                datasource = "onClick"
                currentPage="omni:banker chat:maximize click";
                break;
            case 'CHAT_MINIMIZE':
                console.log('CHAT_MINIMIZE event', e);
                //transactionStatus = "minimize chat";
                datasource = "onClick"
                currentPage="omni:banker chat:minimize click";
                break;
            case 'CHAT_CLOSE_CLICK':
                console.log('CHAT_CLOSE_CLICK event', e);
                //transactionStatus = "close chat";
                datasource = "onClick"
                currentPage="omni:banker chat:close click";
                break;
            case 'CHAT_END_CLICK':
                console.log('CHAT_END_CLICK event', e);
                datasource = "onClick"
                transactionStatus = "end chat";
                currentPage="omni:banker chat:end chat click";
                break;
            case 'CHAT_END':
                console.log('CHAT_END event', e);
                transactionStatus = "end chat";
                currentPage = "omni:banker chat:session ended";
                break;
            case 'CHAT_KEEP_CHATTING':
                console.log('CHAT_KEEP_CHATTING event', e);
                currentPage="omni:banker chat:keep chatting click";
                datasource = "onClick"
                break;
            case 'CHAT_SHRINK_SIZE':
                console.log('CHAT_SHRINK_SIZE', e);
                datasource = "onClick"
                currentPage="omni:banker chat:small view click";
                break;
            case 'CHAT_EXPAND_SIZE':
                console.log('CHAT_EXPAND_SIZE event', e);
                datasource = "onClick"
                currentPage="omni:banker chat:small view maximize click";
                break;
            case 'CHAT_ERROR':
                console.log('CHAT_ERROR event', e);
                currentPage = "omni:banker chat:resume chat error";
                errorMessage = JSON.stringify((e.detail && e.detail.error) || {}).replace(/[{}"]/g, '');
                erroType = true;
                break;
            default:
                console.log('Unknown CHAT_ACTIVITY', e);
        }
        const reportingData = getTealiumObject({currentPage, transactionStatus, datasource, errorMessage, erroType});
        console.log('reportingData', reportingData);
            window.publisherFW.publishEvent(datasource, reportingData);
        }	
    }
     if (e.detail && e.detail.event === "CHAT_ERROR") { clearSessionStorage() };
};

function getTealiumObject({currentPage, transactionStatus, datasource, errorMessage, erroType}) {

	let clickEvent = {
		onClickEvent: currentPage
	}
    if (transactionStatus && datasource === "onClick") {
        clickEvent = {...clickEvent, transactionStatus: transactionStatus}
    }
	let dataSourcePageView = {
		currentPage,
		transactionStatus, //"start chat"
	};
    if (erroType) {
        dataSourcePageView = {currentPage, errorMessage};
    }
	const mergObj = datasource === "onClick" ? clickEvent : dataSourcePageView;
	return {...{
		siteSection: "customer service",
		subSiteSection: "chat",
		languageType: "english",
		channel: (window.appNameForSiteCat || 'OLB'),
		deviceType: (window.uxNameForSiteCat || 'desktop'),
		customerSegment: "customer type " + (getSessionItem("customerTypeCode") || "").toLowerCase(),
		sitePlatform: "omni",
		pageComponents: "aws chat",
		datasource // "pageView"
	}, ...mergObj};
}