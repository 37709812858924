import { getSessionItem } from '../services/sessionManagerService';
import { SESSION_CHAT_STATUS } from './constants';

export default function chatnotificationhandler() {
	const chatNotificationIcon = document.getElementById('chatNotificationIcon');
	if (getSessionItem(SESSION_CHAT_STATUS) === 'minimized') {
		if (chatNotificationIcon) {
			chatNotificationIcon.style.visibility = 'visible';
			chatNotificationIcon.style.display = 'flex';
		}
	} else {
		chatNotificationIcon.style.visibility = 'none';
		chatNotificationIcon.style.display = 'none';
	}
}
