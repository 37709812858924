// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import 'amazon-connect-chatjs';
import { CONTACT_STATUS } from '../../constants/global';
import { modelUtils } from './datamodel/Utils';
import {
	ContentType,
	PARTICIPANT_MESSAGE,
	Direction,
	Status,
	ATTACHMENT_MESSAGE,
	AttachmentErrorType,
	PARTICIPANT_TYPES,
} from './datamodel/Model';
import chatnotificationhandler from '../../utils/chatnotificationhandler';
import clearSessionStorage from '../../services/sessionManagerService';
import ChatJSClient from './ChatJSClient';

const SYSTEM_EVENTS = Object.values(ContentType.EVENT_CONTENT_TYPE);

class ChatSession {
	transcript = [];
	typingParticipants = [];
	thisParticipant = null;
	client = null;
	contactId = null;
	contactStatus = CONTACT_STATUS.DISCONNECTED;

	/**
	 * Flag set when an outgoing message from the Customer is in flight.
	 * Until the request completes, we will not render a Customer message over the websocket.
	 *
	 * @type {boolean}
	 */
	isOutgoingMessageInFlight = false;

	_eventHandlers = {
		'transcript-changed': [],
		'typing-participants-changed': [],
		'contact-status-changed': [],
		'incoming-message': [],
		'outgoing-message': [],
		'chat-disconnected': [],
		'chat-closed': [],
	};

	constructor(chatDetails, displayName, region, stage) {
		console.log(
			'Chat session contructor ',
			chatDetails,
			displayName,
			region,
			stage,
		);

		this.client = new ChatJSClient(chatDetails, region, stage);
		this.contactId = this.client.getContactId();
		this.thisParticipant = {
			participantId: this.client.getParticipantId(),
			displayName: displayName,
		};
	}

	// Callbacks
	onChatDisconnected(callback) {
		console.log('onChatDisconnected');
		this.on('chat-disconnected', function (...rest) {
			callback(...rest);
		});
	}

	onChatClose(callback) {
		console.log('onChatClose');

		this.on('chat-closed', function (...rest) {
			callback(...rest);
		});
	}

	onIncoming(callback) {
		console.log('onIncoming');

		this.on('incoming-message', function (...rest) {
			callback(...rest);
		});
	}

	onOutgoing(callback) {
		console.log('onOugoing');

		this.on('outgoing-message', function (...rest) {
			callback(...rest);
		});
	}

	// Decorators
	incomingItemDecorator(item) {
		console.log('incomingItemDecorator');

		return item;
	}

	outgoingItemDecorator(item) {
		console.log('outgoingItemDecorator');

		return item;
	}

	// CHAT API
	openChatSession() {
		console.log('openChatSession');

		this._addEventListeners();
		this._updateContactStatus(CONTACT_STATUS.CONNECTING);
		return this.client.connect().then(
			(response) => {
				this._updateContactStatus(CONTACT_STATUS.CONNECTED);
				return response;
			},
			(error) => {
				this._updateContactStatus(CONTACT_STATUS.DISCONNECTED);
				return Promise.reject(error);
			},
		);
	}

	async endChat() {
		console.log('endChat');

		await this.client.disconnect();
		this._updateContactStatus(CONTACT_STATUS.DISCONNECTED);
		this._triggerEvent('chat-disconnected');
		this._triggerEvent('chat-closed');
	}

	closeChat() {
		console.log('closeChat');

		this._triggerEvent('chat-closed');
	}

	sendTypingEvent() {
		console.log('Calling SendEvent API for Typing');
		return this.client.sendTypingEvent();
	}

	addOutgoingMessage(data) {
		console.log('addOutgoingMessage', data);

		const message = modelUtils.createOutgoingTranscriptItem(
			PARTICIPANT_MESSAGE,
			{ data: data.text, type: ContentType.MESSAGE_CONTENT_TYPE.TEXT_PLAIN },
			this.thisParticipant,
		);
		console.log('addOutgoingMessage, messageItem is:', message);

		this._addItemsToTranscript([message]);

		this.isOutgoingMessageInFlight = true;

		this.client
			.sendMessage(message.content)
			.then((response) => {
				console.log('send success');
				console.log(response);
				this._replaceItemInTranscript(
					message,
					modelUtils.createTranscriptItemFromSuccessResponse(message, response),
				);

				this.isOutgoingMessageInFlight = false;
				return response;
			})
			.catch((error) => {
				this.isOutgoingMessageInFlight = false;

				this._failMessage(message);
			});
	}

	addOutgoingAttachment(attachment) {
		console.log('addOutgoingAttachment');

		const transcriptItem = modelUtils.createOutgoingTranscriptItem(
			ATTACHMENT_MESSAGE,
			attachment,
			this.thisParticipant,
		);
		this._addItemsToTranscript([transcriptItem]);
		return this.sendAttachment(transcriptItem);
	}

	sendAttachment(transcriptItem) {
		console.log('sendAttachment');

		const { participantId, displayName } = this.thisParticipant;
		return this.client
			.sendAttachment(transcriptItem.content)
			.then((response) => {
				console.log('RESPONSE', response);
				console.log('sendAttachment response:', response);
				this.transcript.splice(this.transcript.indexOf(transcriptItem), 1);
				return response;
			})
			.catch((error) => {
				transcriptItem.transportDetails.error = {
					type: error.type,
					message: error.message,
				};
				if (error.type !== AttachmentErrorType.ValidationException) {
					transcriptItem.transportDetails.error.message =
						'Attachment failed to send';
					transcriptItem.transportDetails.error.retry = () => {
						const newTranscriptItem = modelUtils.createOutgoingTranscriptItem(
							ATTACHMENT_MESSAGE,
							transcriptItem.content,
							{ displayName, participantId },
						);
						newTranscriptItem.id = transcriptItem.id;
						this._replaceItemInTranscript(transcriptItem, newTranscriptItem);
						this.sendAttachment(newTranscriptItem);
					};
				}
				this._failMessage(transcriptItem);
			});
	}

	downloadAttachment(attachmentId) {
		console.log('downloadAttachment');

		return this.client.downloadAttachment(attachmentId);
	}

	loadPreviousTranscript() {
		console.log('loadPreviousTranscript in single');
		var args = {};
		if (this.transcript.length > 0) {
			args.startPosition = {};
			args.startPosition.id = this.transcript[0].id;
		}
		args.scanDirection = 'BACKWARD';
		args.sortOrder = 'ASCENDING';
		args.maxResults = 15;
		return this._loadTranscript(args);
	}

	// EVENT HANDLING

	on(eventType, handler) {
		console.log('on ', eventType, handler);

		if (this._eventHandlers[eventType].indexOf(handler) === -1) {
			this._eventHandlers[eventType].push(handler);
		}
	}

	off(eventType, handler) {
		console.log('off ', eventType, handler);

		const idx = this._eventHandlers[eventType].indexOf(handler);
		if (idx > -1) {
			this._eventHandlers[eventType].splice(idx, 1);
		}
	}

	_triggerEvent(eventType, payload) {
		console.log('triggerEvent ', eventType, payload);

		this._eventHandlers[eventType].forEach((handler) => {
			handler(payload);
		});
	}

	_updateTranscript(transcript) {
		console.log('updateTranscript ', transcript);

		this.transcript = transcript;
		this._triggerEvent('transcript-changed', transcript);
	}

	_updateTypingParticipants(typingParticipants) {
		console.log('_updateTypingParticipants ', typingParticipants);

		this.typingParticipants = typingParticipants;
		this._triggerEvent('typing-participants-changed', typingParticipants);
	}

	_updateContactStatus(contactStatus) {
		console.log('_updateContactStatus ', contactStatus);
		if (
			contactStatus === CONTACT_STATUS.ENDED ||
			contactStatus === CONTACT_STATUS.DISCONNECTED
		) {
			//sessionStorage.setItem('chatEnded', true);
			console.log(
				'Calling clearSessionStorage from ChatSession.js  	_updateContactStatus(contactStatus) ',
			);
			clearSessionStorage();
		}
		this.contactStatus = contactStatus;
		this._triggerEvent('contact-status-changed', contactStatus);
	}

	_addEventListeners() {
		console.log('_addEventListeners ');

		this.client.onMessage((data) => {
			this._handleIncomingData(data);
		});
		this.client.onTyping((data) => {
			this._handleTypingEvent(data);
		});

		this.client.onEnded((data) => {
			this._handleEndedEvent(data);
		});
		this.client.onConnectionEstablished(() => {
			this._loadLatestTranscript();
		});
	}

	// TRANSCRIPT
	_loadLatestTranscript() {
		console.log('loadPreviousTranscript in single');
		return this._loadTranscript({
			scanDirection: 'BACKWARD',
			sortOrder: 'ASCENDING',
			maxResults: 15,
		});
	}

	_loadTranscript(args) {
		console.log('_loadTranscript ', args);

		return this.client
			.getTranscript(args)
			.then((response) => {
				var incomingDataList = response.data.Transcript;
				console.log('in _loadTranscript');
				console.log(response);
				const transcriptItems = incomingDataList.map((data) => {
					var transcriptItem = modelUtils.createItemFromIncoming(
						data,
						this.thisParticipant,
					);
					return transcriptItem;
				});
				this._addItemsToTranscript(transcriptItems);
			})
			.catch((err) => {
				console.error(
					'CustomerUI',
					'ChatSession',
					'transcript fetch error: ',
					err,
				);
			});
	}

	_handleIncomingData(dataInput) {
		console.log('_handleIncomingData ', dataInput);
		chatnotificationhandler();

		var data = dataInput.data;
		var item = modelUtils.createItemFromIncoming(data, this.thisParticipant);

		console.log('_handleIncomingData item created');
		console.log(item);

		if (item) {
			if (!this._isRoundtripMessage(data)) {
				this._updateTypingParticipantsUsingIncoming(item);
			}
			console.log('_handleIncomingData item created');

			if (item.transportDetails.direction === Direction.Incoming) {
				this._triggerEvent('incoming-message', data);
			} else {
				this._triggerEvent('outgoing-message', data);
			}

			const shouldBypassAddItemToTranscript =
				this.isOutgoingMessageInFlight === true &&
				item.participantRole === PARTICIPANT_TYPES.CUSTOMER;

			if (!shouldBypassAddItemToTranscript) {
				this._addItemsToTranscript([item]);
			}
		} else {
			console.log('_handleIncomingData NOT NOT item created');
		}
	}

	_failMessage(message) {
		console.log('_failMessage ', message);

		// Failed messages are going to be inserted into the transcript with a fake timestamp
		// that is 1ms higher than the timestamp of the last existing message or 0 if no such
		// message exists.
		const sentTime =
			this.transcript.length > 0
				? this.transcript[this.transcript.length - 1].transportDetails
						.sentTime + 0.001
				: 0;
		this._replaceItemInTranscript(
			message,
			modelUtils.createFailedItem(message, sentTime),
		);
	}

	_isRoundTripSystemEvent(item) {
		console.log('_isRoundTripSystemEvent ', item);

		return (
			SYSTEM_EVENTS.indexOf(item.contentType) !== -1 &&
			this.thisParticipant.participantId === item.participantId
		);
	}

	_addItemsToTranscript(items) {
		console.log('_addItemsToTranscript ', items);

		let self = this;

		if (items.length === 0) {
			return;
		}

		items = items.filter((item) => !this._isRoundTripSystemEvent(item));

		console.log('ADD ITEMS', items);

		const newItemMap = items.reduce(
			(acc, item) => ({ ...acc, [item.id]: item }),
			{},
		);

		const newTranscript = this.transcript.filter(
			(item) => newItemMap[item.id] === undefined,
		);

		newTranscript.push(...items);
		newTranscript.sort((a, b) => {
			const isASending = a.transportDetails.status === Status.Sending;
			const isBSending = b.transportDetails.status === Status.Sending;
			if ((isASending && !isBSending) || (!isASending && isBSending)) {
				return isASending ? 1 : -1;
			}
			return a.transportDetails.sentTime - b.transportDetails.sentTime;
		});

		newTranscript.forEach(function (item) {
			if (item.transportDetails.direction === Direction.Incoming) {
				item = self.incomingItemDecorator(item);
			} else {
				item = self.outgoingItemDecorator(item);
			}
		});

		this._updateTranscript(newTranscript);
	}

	_replaceItemInTranscript(oldItem, newItem) {
		console.log('_replaceItemInTranscript ', oldItem, newItem);

		const idx = this.transcript.indexOf(oldItem);
		if (idx > -1) {
			this.transcript.splice(idx, 1);
		}
		this._addItemsToTranscript([newItem]);
	}

	_isRoundtripMessage(item) {
		console.log('_isRoundtripMessage ', item);

		return this.thisParticipant.participantId === item.ParticipantId;
	}

	/** called when transcript has chat ended message */
	_handleEndedEvent() {
		console.log('_handleEndedEvent ');

		this._updateContactStatus(CONTACT_STATUS.ENDED);
		this._triggerEvent('chat-disconnected');
	}

	// TYPING PARTICIPANTS

	_handleTypingEvent(dataInput) {
		console.log('_handleTypingEvent ', dataInput);

		var data = dataInput.data;
		if (this._isRoundtripMessage(data)) {
			return;
		}
		var incomingTypingParticipant = modelUtils.createTypingParticipant(
			data,
			this.thisParticipant.participantId,
		);
		incomingTypingParticipant.callback = setTimeout(() => {
			this._removeTypingParticipant(incomingTypingParticipant.participantId);
		}, 12 * 1000);
		var newTypingParticipants = [];
		for (var i = 0; i < this.typingParticipants.length; i++) {
			var existingParticipantTyping = this.typingParticipants[i];
			if (
				existingParticipantTyping.participantId ===
				incomingTypingParticipant.participantId
			) {
				clearTimeout(existingParticipantTyping.callback);
			} else {
				newTypingParticipants.push(existingParticipantTyping);
			}
		}
		newTypingParticipants.push(incomingTypingParticipant);
		this._updateTypingParticipants(newTypingParticipants);
		console.log('this.typingParticipants');
		console.log(this.typingParticipants);
	}

	_updateTypingParticipantsUsingIncoming(item) {
		console.log('_updateTypingParticipantsUsingIncoming ', item);

		if (item.type === PARTICIPANT_MESSAGE) {
			this._removeTypingParticipant(item.participantId);
		}
	}

	_removeTypingParticipant(participantId) {
		console.log('_removeTypingParticipant ', participantId);

		//this.typingParticipants = this.typingParticipants.filter(
		//  tp => tp.participantDetails.participantId !== participantId
		//);
		this._updateTypingParticipants([]);
	}
}

export default ChatSession;
