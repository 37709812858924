export default function closeChatSession(afterChatCB) {
	console.log('Inside closeChat');

	if (window.onEndChat) {
		window
			.onEndChat()
			.then((resp) => {
				console.log('After closeChat Success');
				const chatCloseEvent = new CustomEvent('CHAT_ACTIVITY', {
					bubbles: true,
					detail: { event: 'CHAT_CLOSE' },
				});
				window.dispatchEvent(chatCloseEvent);
				if (afterChatCB) {
					afterChatCB();
				}
			})
			.catch((err) => {
				console.error('After closeChat Error', err);
				if (afterChatCB) {
					afterChatCB();
				}
			});
	} else {
		console.log('No Chat Session or scripts available');
		if (afterChatCB) {
			afterChatCB();
		}
	}
}
