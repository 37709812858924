// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from 'react';
import { ThemeProvider } from './theme';
import styled, { createGlobalStyle } from 'styled-components';
import ChatContainer from './components/Chat/ChatContainer';
import defaultTheme from './theme/defaultTheme';
import { visualViewportHandler } from './utils/visualViewportHandler';
import {tealiumHandler} from "./utils/tealiumHandler";

const GlobalStyle = createGlobalStyle`
  html { 
     height: 100%;
  }
`;
//position: fixed;
//bottom: 0px;
//right: 0px;
const Page = styled.div`
	position: fixed;
	width: 100%;
	bottom: 0px;
	right: 0px;
	height: 100%;
	font-family: ${(props) => props.theme.fonts.regular};
	border-collapse: collapse;
	box-shadow: 0px 2px 3px ${(props) => props.theme.palette.alto};
	box-sizing: border-box;
	z-index: 10000;
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}

	@media screen and (min-width: 600px) {
		border-radius: 8px 8px 0px 0px;
		right: 35px;
		bottom: 35px;
		width: 320px;
		height: 553px;
		max-height: 95% !important;
		z-index: 10000;
	}
	@media screen and (max-device-height: 600px) and (orientation: landscape) { 
		width: 320px !important;
		height: calc(100% - 35px) !important;
		max-height: 95%;
	}
`;

const FloatingButtonText = styled.div`
	grid-column-start: 1;
	justify-self: center;
	margin-right: 15px;
`;

const MaximizeChat = styled.div`
	grid-column-start: 2;
`;

const NotificationIcon = styled.div`
	position: relative;
	border-radius: 8px;
	width: 16px;
	height: 16px;
	right: -145px;
	top: -30px;
	background: #de162b;
	display: none;
	@media screen and (max-width: 600px) {
		right: -130px;
		top: -23px;
	}
`;

const FloatingBtnOuter = styled.button`
	all: unset;
	font-family: Helvetica Neue;
	color: white;
	background: #0c2074;
	width: 202px;
	height: 57.9px;
	justify-content: center;
	text-align: center;
	align-items: center;
	border-radius: 29px;
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 10000;
	border: 2px solid #ffffff;
	box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.25);
	font-size: 16px;
	font-weight: bold;
	display: none;
	grid-template-columns: [first] auto [line2] 26px [end];
	&:focus {
		outline-offset: 0.1rem !important;
		outline: 3px solid #61a0f7;
	}
	@media screen and (max-width: 600px) {
		width: 171px;
		height: 45px;
		border-radius: 22.5px;
		font-size: 14px;
	}
`;

const UpArrow = styled.div`
box-sizing: border-box;
position: relative;
display: block;
transform: scale(var(--ggs,1));
width: 22px;
height: 22px;
border: 2px solid transparent;
border-radius: 100px
&::after {
content: "";
display: block;
box-sizing: border-box;
position: absolute;
width: 10px;
height: 10px;
border-top: 1.5px solid white;
border-right: 1.5px solid white;
transform: rotate(-45deg);
left: 4px;
bottom: 1px
}`;

const AppProvider = (props) => {
	return (
		<ThemeProvider theme={Object.assign({}, defaultTheme, props.themeConfig)}>
			{props.children}
		</ThemeProvider>
	);
};

App.defaultProps = {
	baseCssClass: 'connect-customer-interface',
};

function App({ baseCssClass, ...props }) {

	useEffect(() => {
		//if on lower devices then we need to resize chat widget based on virtual keyboard open or not.
		registredEventForTealium();
		if (window.innerWidth <= 600) {
			registerVisualViewportChangeListener();
		} else {
			console.log(
				'NOT registering VisualViewportChangeListener, window.innerWidth=',
				window.innerWidth,
			);
		}
		// remove the event listener when component unmounts
		return () => {
			if (window.innerWidth <= 600) {
				removeVisualViewportChangeListener();
				removeTealiumEvent();
			}
		};
	});
	return (
		<AppProvider themeConfig={props.themeConfig || {}}>
			<GlobalStyle />
			<Page
				id='chat-widget'
				aria-labelledby='chat-widget'
				className={`connect-customer-interface`}>
				<ChatContainer id='chat-widget-container' {...props} />
			</Page>
			<FloatingBtnOuter
				tabIndex={0}
				aria-label='maximize chat'
				className='floatingBtn'
				id='floatingBtn'
				onClick={() => window.connect.maximizeChat()}>
				<NotificationIcon
					id='chatNotificationIcon'
					aria-label='new unread message'
				/>
				<FloatingButtonText>Live agent chat </FloatingButtonText>
				<MaximizeChat>
					<UpArrow alt='up'/>
				</MaximizeChat>
			</FloatingBtnOuter>
		</AppProvider>
	);
}

export default App;

function registerVisualViewportChangeListener() {
	if (window.visualViewport) {
		console.log(
			'registering VisualViewportChangeListener, window.innerWidth=',
			window.innerWidth,
		);
		// window.visualViewport.addEventListener(
		// 	'scroll',
		// 	visualViewportScrollHandler,
		// );
		window.visualViewport.addEventListener('resize', visualViewportHandler);
	} else {
		console.log(
			'NOT registering VisualViewportChangeListener because window.visualViewport is ',
			window.visualViewport,
		);
	}
}
function removeVisualViewportChangeListener() {
	if (window.visualViewport) {
		console.log('Removing registered VisualViewportChangeListener');
		// window.visualViewport.removeEventListener(
		// 	'scroll',
		// 	visualViewportScrollHandler,
		// );
		window.visualViewport.removeEventListener('resize', visualViewportHandler);
	}
}

function registredEventForTealium() {
	window.addEventListener('CHAT_ACTIVITY', tealiumHandler);
}

function removeTealiumEvent(){
	window.removeEventListener('CHAT_ACTIVITY', tealiumHandler);
}